import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { SmallLoader } from '@atoms/notifications'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, ProductListFragment, ProductDetailsFragment, useAddProductToWishlistMutation, useRemoveProductFromWishlistMutation } from '@hooks/api/index'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'

const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '15px', tablet: '23px', desktop: '23px' })}
  ${ResponsivePXValue('height', { mobile: '15px', tablet: '23px', desktop: '23px' })}
`

export interface FavouriteButtonProps {
  product: ProductListFragment | ProductDetailsFragment
  className?: string
}

interface FavouriteButtonState {
  hovering: boolean
}

const DEFAULT_STATE: FavouriteButtonState = {
  hovering: false,
}

// TODO Will move and refactor in the review ticket

export function FavouriteButton({ product, className }: FavouriteButtonProps): JSX.Element {

  const { data: customerData } = useCustomerQuery()
  const [addProductToWishlist, { loading: addWishlistLoading }] = useAddProductToWishlistMutation()
  const [removeProductFromWishlist, { loading: removeWishlistLoading }] = useRemoveProductFromWishlistMutation()
  const [state, setState] = useState<FavouriteButtonState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const { addToast } = useSimpleToasts()

  const _handleClick = async (): Promise<void> => {

    if (customerData?.currentCustomer?.customerType !== CustomerTypeEnum.REGISTERED) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else if (!product.isInWishlist) {
      try {
        await addProductToWishlist({
          variables: {
            wishlistId: customerData?.currentCustomer?.wishlistId+'',
            sku: product.sku,
            quantity: 1,
          },
        })

        addToast({
          message: 'Product added to favourites',
          appearance: 'success',
        })
      } catch (err) {
        addToast({
          message: 'Oops. Something went wrong. Please try again later.',
          appearance: 'error',
        })
      }
    } else {
      try {
        await removeProductFromWishlist({
          variables: {
            wishlistId: customerData?.currentCustomer?.wishlistId+'',
            wishlistItemId: product.wishlistItemId+'',
          },
        })
        addToast({
          message: 'Product removed from favorite',
          appearance: 'success',
        })
      } catch (err) {
        addToast({
          message: 'Oops. Something went wrong. Please try again later.',
          appearance: 'error',
        })
      }
    }
  }

  const color = theme.colors.green.bottleGreen

  return (
    <IconContainer
      className={className}
      onClick={_handleClick}
      onMouseEnter={(): void => setState((prevState) => update(prevState, { hovering: { $set: true } }))}
      onMouseLeave={(): void => setState((prevState) => update(prevState, { hovering: { $set: false } }))}>
      <Choose>
        <When condition={addWishlistLoading || removeWishlistLoading}>
          <SmallLoader color={color} />
        </When>
        <Otherwise>
          <Icon icon={
            product?.isInWishlist
              ? LocalIconEnums.HEART_FILLED
              : state.hovering
                ? LocalIconEnums.HEART_OUTLINE
                : LocalIconEnums.HEART_OUTLINE
          } color={color} />
        </Otherwise>
      </Choose>
    </IconContainer>
  )

}
